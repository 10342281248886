import { SET_LOADING } from "constants/action";
import { AnyAction } from "redux";

interface ApiState {
    loading: boolean;
}

const initState: ApiState = {
    loading: false,
};

const apiReducer = (state = initState, action: AnyAction) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                loading: action.isShow,
            };
        default:
            return state;
    }
};

export default apiReducer;
