import { errorRef, push } from "App";
import Axios from "axios";
import { CellCurrency } from "components/CellCurrency";
import { FontType, Label } from "components/Label";
import { RESPONSE_OK } from "constants/api";
import { ENV } from "constants/app";
import { Channel, DisplayMode, Locale, MarketSegment, WhiteLabelCode } from "constants/enum";
import message from "constants/ja/message.json";
import { RATE_DETAIL_URL } from "constants/routePath";
import { RateListData } from "interfaces/models";
import ip from "ip";
import React from "react";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { getInitPriceListAndSettings } from "services/nowPriceInfoServiceNs";
import { getSymbolAll } from "services/symbolServiceNs";
import { RootState } from "store";
import { pushRateList, setPushError, setRateList } from "store/push/action";
import { parseNumber, parseNumberString, decimalTrunc } from "utils";
import "./style.scss";

const allSymbolCode = ["2001", "2002", "2003", "2004", "2005", "2006", "2007", "2008", "2045", "2010", "2011", "2012"];
const majorSymbolCode = ["2001", "2002", "2003", "2004", "2010", "2011"];

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            pushRateList,
            setRateList,
            setPushError,
        },
        dispatch,
    );

const mapStateToProps = ({ push }: RootState) => {
    return {
        push,
    };
};

interface Props extends ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> {}

class RatePage extends React.PureComponent<Props> {
    cts = Axios.CancelToken.source();

    async componentDidUpdate(prevProps: Props) {
        const { push, setPushError } = this.props;
        if (push.isError && push.isError !== prevProps.push.isError) {
            const errorModal = errorRef.current;
            await errorModal?.show(push.errorMessage || message.MSG004);
            setPushError(false);
            // Retry
            this.connectPush();
        }
    }

    async componentDidMount() {
        // A
        const symbolServiceNsParams = {
            ipAddress: ip.address(),
            marketDivision: MarketSegment.OTC,
            whiteLabel: WhiteLabelCode.FXRate,
            channel: Channel.Web,
            locale: Locale.Japanese,
        };
        const getSA = getSymbolAll(symbolServiceNsParams, this.cts.token);
        // B
        const nowPriceInfoServiceNsParams = {
            ipAddress: ip.address(),
            marketDivision: MarketSegment.OTC,
            whiteLabel: WhiteLabelCode.FXRate,
            channel: Channel.Web,
        };
        const getIPL = getInitPriceListAndSettings(nowPriceInfoServiceNsParams, this.cts.token);
        const symbolAllRes = await getSA;
        const initPriceListAndSettingsRes = await getIPL;

        const rateListData = [] as RateListData[];
        if (symbolAllRes.result === RESPONSE_OK && initPriceListAndSettingsRes.result === RESPONSE_OK) {
            symbolAllRes.symbolDtos.forEach((itemSymbol) => {
                initPriceListAndSettingsRes.priceAndQuotationDtos.forEach((itemPriceList) => {
                    if (itemSymbol.symbolCode === itemPriceList.symbolCode) {
                        const bidRate = parseNumber(itemPriceList.bidRate);
                        const askRate = parseNumber(itemPriceList.askRate);
                        const pips = parseNumber(itemSymbol.pips);
                        const previousClosePrice = parseNumber(itemPriceList.previousClosePrice);
                        const scale = parseNumber(itemSymbol.decimalEffectiveDigit);
                        const decimalEffectiveDigit = scale > 0 ? scale : 0;
                        const decimal = Math.pow(10, decimalEffectiveDigit);
                        const mid = decimalTrunc(((bidRate + askRate) * decimal) / 2, decimalEffectiveDigit);
                        rateListData.push({
                            symbolCode: itemSymbol.symbolCode,
                            symbolName: itemSymbol.symbolName,
                            decimalEffectiveDigit,
                            pips,
                            symbolSequence: itemSymbol.symbolSequence,
                            bidRate,
                            askRate,
                            spread: (askRate - bidRate) * Math.pow(10, pips),
                            bidSwap: parseNumberString(itemPriceList.bidSwap),
                            askSwap: parseNumberString(itemPriceList.askSwap),
                            previousClosePriceApi: previousClosePrice,
                            previousClosePrice: (parseNumber(mid) - previousClosePrice * decimal) / decimal,
                            highPrice: parseNumber(itemPriceList.highPrice),
                            lowPrice: parseNumber(itemPriceList.lowPrice),
                            bidArrowImg: "",
                            isShowBidArrowImg: false,
                            classNameBid: "black01",
                            askArrowImg: "",
                            isShowAskArrowImg: false,
                            classNameAsk: "black01",
                        });
                    }
                });
            });
        }
        const sortRateList = rateListData.sort((a, b) => {
            return Number(a.symbolSequence) - Number(b.symbolSequence);
        });
        const params = new URLSearchParams(window.location.search);
        const mode = Number(params.get("mode"));

        const rateListFilter = sortRateList.filter((item) => {
            if (mode === DisplayMode.MajorCurrencies) {
                // Show major currencies
                return majorSymbolCode.includes(item.symbolCode);
            }

            return allSymbolCode.includes(item.symbolCode);
        });
        this.props.setRateList(rateListFilter);
        // Push event
        if (!ENV.DEV) {
            this.connectPush();
        }
    }

    componentWillUnmount = () => {
        this.cts.cancel();
        push.closeWebSocket();
    };

    connectPush = () => {
        const { rateList } = this.props.push;
        const symbolCodeList = rateList.map((item) => item.symbolCode);
        this.props.pushRateList(symbolCodeList);
    };

    onCellCurrencyClick = () => {
        window.open(RATE_DETAIL_URL);
    };

    onPause = () => {
        push.closeWebSocket();
    };

    onPlay = () => {
        this.connectPush();
    };

    render() {
        const { push } = this.props;

        return (
            <div className="rate-page">
                {ENV.DEV && (
                    <>
                        <button onClick={this.onPause}>Pause Push</button>
                        <button onClick={this.onPlay}>Continue Push</button>
                    </>
                )}
                <div className="title">
                    <div className="currency-pair-title">
                        <Label
                            className="fs-18 lh-36 primary"
                            text="通貨ペア"
                            fontFamily={FontType.NotoSansJPRegular}
                        />
                    </div>
                    <div className="currency-pair-unit">
                        <Label className="fs-18 lh-24 bid bid-unit" text="BID" fontFamily={FontType.RobotoRegular} />
                        <Label
                            className="fs-18 lh-24 primary spread-unit"
                            text="SP"
                            fontFamily={FontType.RobotoRegular}
                        />
                        <Label className="fs-18 lh-24 ask ask-unit" text="ASK" fontFamily={FontType.RobotoRegular} />
                    </div>
                </div>
                {push.rateList.map((item: RateListData, index: number) => {
                    return (
                        <CellCurrency
                            key={index}
                            symbolCode={item.symbolCode}
                            bidRate={item.bidRate}
                            bidArrowImg={item.bidArrowImg}
                            isShowBidArrowImg={item.isShowBidArrowImg}
                            classNameBid={item.classNameBid}
                            spread={item.spread}
                            askRate={item.askRate}
                            askArrowImg={item.askArrowImg}
                            isShowAskArrowImg={item.isShowAskArrowImg}
                            classNameAsk={item.classNameAsk}
                            askSwap={item.askSwap}
                            bidSwap={item.bidSwap}
                            previousClosePrice={item.previousClosePrice}
                            highPrice={item.highPrice}
                            lowPrice={item.lowPrice}
                            scale={item.decimalEffectiveDigit}
                            onClick={this.onCellCurrencyClick}
                        />
                    );
                })}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RatePage);
