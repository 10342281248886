import { CurrencyPair } from "components/CurrencyPair";
import { FontType, Label } from "components/Label";
import { LabelNumber } from "components/LabelNumber";
import { RateNumber } from "components/RateNumber";
import React from "react";
import "./style.scss";

interface Props {
    className?: string;
    symbolCode: string;
    bidRate: number;
    askRate: number;
    bidArrowImg: string;
    isShowBidArrowImg: boolean;
    classNameBid: string;
    spread: number;
    askArrowImg: string;
    isShowAskArrowImg: boolean;
    classNameAsk: string;
    askSwap: string;
    bidSwap: string;
    previousClosePrice: number;
    highPrice: number;
    lowPrice: number;
    scale: number;
    onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

class CellCurrency extends React.PureComponent<Props> {
    static defaultProps = {
        className: "",
    };

    cellRef = React.createRef<HTMLDivElement>();

    render() {
        const {
            className,
            symbolCode,
            bidRate,
            bidArrowImg,
            isShowBidArrowImg,
            classNameBid,
            spread,
            askRate,
            askArrowImg,
            isShowAskArrowImg,
            classNameAsk,
            askSwap,
            bidSwap,
            previousClosePrice,
            highPrice,
            lowPrice,
            scale,
            onClick,
        } = this.props;

        return (
            <div
                ref={this.cellRef}
                className={`cell-currency ${className}`}
                onClick={(ev) => {
                    onClick?.(ev);
                    this.cellRef.current?.classList.remove("hover-t");
                }}
                onMouseOver={() => {
                    this.cellRef.current?.classList.add("hover-t");
                }}
                onMouseLeave={() => {
                    this.cellRef.current?.classList.remove("hover-t");
                }}
            >
                <CurrencyPair symbolCode={symbolCode} />
                <div className="stock-content">
                    <div className="stock-above">
                        <RateNumber
                            className="pl-46"
                            arrowImg={bidArrowImg}
                            value={bidRate}
                            isShowArrow={isShowBidArrowImg}
                            classNameRate={classNameBid}
                            scale={scale}
                        />
                        <div className="spread">
                            <LabelNumber
                                text={spread}
                                className="fs-18 lh-24"
                                fontFamily={FontType.RobotoCondensedRegular}
                                scale={1}
                                resizeFont={{ maxFontSize: 18, width: 108 }}
                            />
                        </div>
                        <RateNumber
                            className="pr-66"
                            arrowImg={askArrowImg}
                            value={askRate}
                            isShowArrow={isShowAskArrowImg}
                            classNameRate={classNameAsk}
                            scale={scale}
                        />
                    </div>
                    <div className="stock-bellow">
                        <div className="swap-number ask-swap">
                            <Label className="primary fs-18 lh-24 swap-title">
                                買<span style={{ fontFamily: FontType.RobotoRegular, marginTop: 1 }}>Swap</span>
                            </Label>
                            <Label
                                text={askSwap}
                                className="fs-18 lh-24 pl-4 mt-3"
                                fontFamily={FontType.RobotoCondensedRegular}
                                resizeFont={{ maxFontSize: 18, width: 45 }}
                            />
                        </div>
                        <div className="swap-number bid-swap">
                            <Label className="primary fs-18 lh-24">
                                売<span style={{ fontFamily: FontType.RobotoRegular, marginTop: 1 }}>Swap</span>
                            </Label>
                            <Label
                                text={bidSwap}
                                className="fs-18 lh-24 pl-4 mt-3"
                                fontFamily={FontType.RobotoCondensedRegular}
                                resizeFont={{ maxFontSize: 18, width: 45 }}
                            />
                        </div>
                        <div className="swap-number previous-close-price">
                            <Label className="primary fs-18 lh-36" text="前日比" />
                            <LabelNumber
                                text={previousClosePrice}
                                className="fs-18 lh-24 pl-4 mt-3"
                                fontFamily={FontType.RobotoCondensedRegular}
                                scale={scale}
                                isTrunc={true}
                                hasPlus={true}
                                resizeFont={{ maxFontSize: 18, width: 60 }}
                            />
                        </div>
                        <div className="swap-number high-price">
                            <Label
                                className="ask fs-18 lh-24 high-title"
                                text="H"
                                fontFamily={FontType.RobotoRegular}
                            />
                            <LabelNumber
                                text={highPrice}
                                className="fs-18 lh-24 pl-4"
                                fontFamily={FontType.RobotoCondensedRegular}
                                scale={scale}
                                isTrunc={true}
                                resizeFont={{ maxFontSize: 18, width: 61 }}
                            />
                        </div>
                        <div className="swap-number low-price">
                            <Label className="bid fs-18 lh-24" text="L" fontFamily={FontType.RobotoRegular} />
                            <LabelNumber
                                text={lowPrice}
                                className="fs-18 lh-24 pl-4"
                                fontFamily={FontType.RobotoCondensedRegular}
                                scale={scale}
                                isTrunc={true}
                                resizeFont={{ maxFontSize: 18, width: 61 }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { CellCurrency };
