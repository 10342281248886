import { SET_PUSH_ERROR, SET_RATE_LIST } from "constants/action";
import { RateListData } from "interfaces/models";
import { AnyAction } from "redux";

const initialState = {
    rateList: [] as RateListData[],
    isError: false,
    errorMessage: "",
};

const pushReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_RATE_LIST:
            return {
                ...state,
                rateList: action.data as RateListData[],
            };
        case SET_PUSH_ERROR:
            return {
                ...state,
                isError: action.isError as boolean,
                errorMessage: action.message as string,
            };
        default:
            return state;
    }
};

export default pushReducer;
