import { decode } from "@msgpack/msgpack";
import { push } from "App";
import ArrowDown from "assets/images/arrow-down.png";
import ArrowUp from "assets/images/arrow-up.png";
import { SET_PUSH_ERROR, SET_RATE_LIST } from "constants/action";
import { RESPONSE_OK } from "constants/api";
import { ReceivedDataType, SendMessageType } from "constants/enum";
import { ENV_CONFIG_DEFAULT } from "constants/envConfig";
import message from "constants/ja/message.json";
import { RateListData } from "interfaces/models";
import clone from "lodash/clone";
import { pushAuth } from "push/push";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { store } from "store";
import { decimalTrunc, parseNumber } from "utils";
import { logDev } from "utils/logs";

export const setRateList = (data: RateListData[]) => {
    return {
        type: SET_RATE_LIST,
        data,
    };
};

export const setPushError = (isError: boolean, message?: string) => {
    return {
        type: SET_PUSH_ERROR,
        isError,
        message,
    };
};

export const pushRateList = (symbolList: string[]) => {
    return async (dispatch: ThunkDispatch<Promise<void>, {}, AnyAction>): Promise<void> => {
        // Authenticate push request
        const auth = await pushAuth(symbolList);
        logDev("pushAuth OK");
        if (auth) {
            pushStreaming(auth.sessionId, auth.authKey, dispatch);
        } else {
            dispatch(setPushError(true));
        }
    };
};

export const pushStreaming = async (
    sessionId: string,
    authKey: string,
    dispatch: ThunkDispatch<Promise<void>, {}, AnyAction>,
) => {
    logDev("start pushStreaming");
    const streamARQParam = {
        kind: ReceivedDataType.AuthenticationRequest,
        authKey,
        sessionId,
        messageType: SendMessageType.MessagePack,
    };
    const streamBCNParam = {
        kind: ReceivedDataType.Beacon,
        authKey,
        sessionId,
    };

    await push.closeWebSocket();
    const ws = push.initWebSocket(ENV_CONFIG_DEFAULT.SOCKET_URL);
    ws.binaryType = "arraybuffer";
    logDev("ws init done", ws);

    ws.onopen = () => {
        logDev("ws.onopen");
        ws.send(JSON.stringify(streamARQParam));
    };
    let arsInterval: NodeJS.Timeout;
    let lastReceiveTime = Date.now(); // Milliseconds

    ws.onclose = async (ev) => {
        logDev("ws.onclose", ev.reason, ev);
        clearInterval(arsInterval);
        if (window.stopPush) {
            // Proactively stop
            return;
        }
        // Offline
        if (!navigator.onLine) {
            dispatch(setPushError(true, message.MSG004));
        } else {
            dispatch(setPushError(true));
        }
    };
    ws.onerror = () => {
        logDev("ws.onerror");
        ws.close(1000, "ws.onerror");
    };
    ws.onmessage = async (message) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const jsonData: any = decode(message.data);
        switch (jsonData.kind) {
            case ReceivedDataType.AuthenticationResult:
                logDev("ws.onmessage kind ARS", jsonData.result);
                // Send beacon push each 10s
                if (jsonData.result === RESPONSE_OK) {
                    arsInterval = setInterval(() => {
                        if (ws.readyState === ws.OPEN) ws.send(JSON.stringify(streamBCNParam));
                    }, 10000);
                } else {
                    ws.close(1000, `result not OK kind=${jsonData.kind}`);
                }
                break;
            case ReceivedDataType.Beacon:
                if (Date.now() - lastReceiveTime > 30000) {
                    ws.close(1000, `lastReceiveTime > 30s kind=${jsonData.kind}`);
                }
                lastReceiveTime = Date.now();
                break;
            case ReceivedDataType.Error:
                ws.close(1000, `kind=${jsonData.kind}`);
                break;
            case ReceivedDataType.Market:
            default:
                if (typeof jsonData === "string" && jsonData.substring(0, 1) === "M") {
                    const prevRateList = clone(store.getState().push.rateList);
                    const rateList = jsonData.substr(1).split(";");
                    for (let index = 0; index < rateList.length; index++) {
                        const rate = rateList[index].split(",");
                        const symbolCode = rate[2];
                        const aR = parseNumber(rate[0]);
                        const bR = parseNumber(rate[1]);
                        if (symbolCode) {
                            // logDev("symbolCode", symbolCode, "bidRate", bR, "askRate", aR)
                            const prevItem = prevRateList.find((i) => i.symbolCode === symbolCode);
                            if (!prevItem) {
                                continue;
                            }
                            const decimal = Math.pow(10, prevItem.decimalEffectiveDigit);
                            const mid = decimalTrunc(((bR + aR) * decimal) / 2, prevItem.decimalEffectiveDigit);
                            // BID
                            prevItem.bidArrowImg = prevItem.bidRate > bR ? ArrowDown : ArrowUp;
                            prevItem.isShowBidArrowImg = !isNaN(bR) && prevItem.bidRate !== bR;
                            prevItem.classNameBid = isNaN(bR) ? "" :
                                prevItem.bidRate === bR
                                    ? "black01"
                                    : prevItem.bidRate > bR
                                        ? "bid"
                                        : "ask";
                            prevItem.bidRate = bR;
                            // Spread
                            prevItem.spread = (aR - bR) * Math.pow(10, prevItem.pips);
                            // ASK
                            prevItem.askArrowImg = prevItem.askRate > aR ? ArrowDown : ArrowUp;
                            prevItem.isShowAskArrowImg = !isNaN(aR) && prevItem.askRate !== aR;
                            prevItem.classNameAsk = isNaN(aR) ? "" :
                                prevItem.askRate === aR
                                    ? "black01"
                                    : prevItem.askRate > aR
                                        ? "bid"
                                        : "ask";
                            prevItem.askRate = aR;
                            // previousClosePrice
                            prevItem.previousClosePrice = (parseNumber(mid) - prevItem.previousClosePriceApi * decimal) / decimal;
                            // highPrice
                            if (bR > prevItem.highPrice) {
                                prevItem.highPrice = bR;
                            }
                            // lowPrice
                            if (aR < prevItem.lowPrice) {
                                prevItem.lowPrice = aR;
                            }
                        }
                    }
                    dispatch(setRateList(prevRateList));
                }
                break;
        }
    };
};
