import { DEFAULT_EMPTY } from "constants/app";
import { isNullOrEmpty } from "utils";

export const decimalTrunc = (value: string | number, fixed: number) => {
    if (isNullOrEmpty(value)) return DEFAULT_EMPTY;
    const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");

    return Number(value).toString().match(re)?.[0] || DEFAULT_EMPTY;
};

/**
 * @param value The value needs formatting
 * @param scale Rounded to "scale" digits. Default scale = 0
 */
export const formatNumber = (value?: number | string | null, scale = 0, isTrunc = false, hasPlus = false) => {
    if (value === null || value === "" || typeof value === "undefined" || isNaN(+value)) {
        return DEFAULT_EMPTY;
    }
    if (isTrunc) {
        value = decimalTrunc(value, scale);
    }
    const res = new Intl.NumberFormat("ja-JP", {
        style: "decimal",
        minimumFractionDigits: scale,
        maximumFractionDigits: scale,
    }).format(+value);
    if (hasPlus) {
        return `${+value > 0 ? "+" : ""}${res}`;
    }

    return res;
};

export const parseNumber = (value?: number | string | null) => {
    if (value === null || typeof value === "undefined" || value.toString().trim() === "" || isNaN(+value)) {
        return NaN;
    }

    return Number(value);
};

export const parseNumberString = (value?: string | null) => {
    if (value === null || typeof value === "undefined" || value.toString().trim() === "" || isNaN(+value)) {
        return DEFAULT_EMPTY;
    }

    return value;
};
