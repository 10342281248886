/* eslint-disable @typescript-eslint/no-explicit-any */
import { errorRef } from "App";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { API_APP_ERROR_CODE, AXIOS_TIMEOUT_CODE, AXIOS_TIMEOUT_ERROR_MESSAGE } from "constants/api";
import { ErrorCode } from "constants/enum";
import { ENV_CONFIG_DEFAULT } from "constants/envConfig";
import message from "constants/ja/message.json";
import { BaseResponse } from "interfaces/response";
import baseAxios from "services/baseAxios";
import { store } from "store";
import { setLoading } from "store/api/action";
import { logDev, logError } from "utils/logs";

const errorHandle = async (url: string, error: AxiosError, hasLoading: boolean) => {
    logError(error);
    const errorRes = {
        apiPath: url,
        errorCode: error.response?.status || error.message,
    } as BaseResponse;
    const errorModal = errorRef.current;
    if (error?.response) {
        const resultCode = error.response.data?.result;
        if (resultCode === ErrorCode.SystemMaintenance || resultCode === ErrorCode.SystemFailure) {
            await errorModal?.show(message.MSG003);

            // Retry
            return request(url, error.config, hasLoading);
        }
        const statusCode = error.response.status;
        if (statusCode >= 500 && statusCode < 600) {
            const msgApi = error.response.data?.message;
            const msg = msgApi ? `${msgApi}(${statusCode})` : "Server Error";
            await errorModal?.show(msg);

            // Retry
            return request(url, error.config, hasLoading);
        }
    } else {
        const config: AxiosRequestConfig = error?.config;
        if (config && config?.baseURL !== ENV_CONFIG_DEFAULT.API_PUSH_URL) {
            switch (error.code) {
                case AXIOS_TIMEOUT_CODE:
                    if (error.message === AXIOS_TIMEOUT_ERROR_MESSAGE) {
                        await errorModal?.show(message.MSG002);

                        // Retry
                        return request(url, config, hasLoading);
                    }

                    return errorRes;
            }
        }
        if (error?.isAxiosError && config?.baseURL !== ENV_CONFIG_DEFAULT.API_PUSH_URL) {
            // Network error
            await errorModal?.show(message.MSG001);

            // Retry
            return request(url, config, hasLoading);
        }
    }
    // Unexpected error
    logError("Call api unexpected error");
    const config = error?.config;
    if (config?.baseURL === ENV_CONFIG_DEFAULT.API_PUSH_URL) {
        await errorModal?.show(message.MSG004);

        // Retry
        return request(url, config, hasLoading);
    }

    return errorRes;
};

let callNumber = 0;
const startLoading = (hasLoading: boolean) => {
    if (hasLoading) {
        callNumber++;
        store.dispatch(setLoading(true));
    }
};

const endLoading = (hasLoading: boolean) => {
    if (hasLoading) {
        callNumber--;
        if (callNumber > 0) {
            return;
        }
        store.dispatch(setLoading(false));
    }
};

const request = async <T = any>(
    url: string,
    config: AxiosRequestConfig,
    hasLoading: boolean,
): Promise<T & BaseResponse> => {
    startLoading(hasLoading);
    let result = null;
    try {
        logDev("\x1b[32m%s\x1b[0m", "INFO call api:", JSON.stringify(config));
        const response = await baseAxios(config);
        result = response.data;
        endLoading(hasLoading);
        const resultCode = result?.result;
        if (resultCode === ErrorCode.SystemMaintenance || resultCode === ErrorCode.SystemFailure) {
            // eslint-disable-next-line no-throw-literal
            throw {
                message: resultCode,
                config,
                response,
            };
        }
    } catch (error) {
        endLoading(hasLoading);
        // CancelToken
        if (axios.isCancel(error)) {
            logDev("\x1b[32m%s\x1b[0m", "INFO request canceled", JSON.stringify(config), error.message);
            result = {
                apiPath: url,
                errorCode: API_APP_ERROR_CODE.CANCEL,
            } as BaseResponse;
        } else {
            result = await errorHandle(url, error, hasLoading);
        }
    }

    return result;
};

const api = {
    post: <T = any>(url: string, data?: any, config?: AxiosRequestConfig, hasLoading = true) => {
        return request<T>(url, { method: "post", url, data, ...config }, hasLoading);
    },
    push: <T = any>(url: string, data?: any, config?: AxiosRequestConfig, hasLoading = false) => {
        return request<T>(
            url,
            { method: "post", url, data, ...config, baseURL: ENV_CONFIG_DEFAULT.API_PUSH_URL },
            hasLoading,
        );
    },
};

export default api;
