import { PUSH_CONNECT, PUSH_FILTERING, PUSH_LOGIN } from "constants/api";
import { ConnectRequest, FilterRequest, LoginRequest } from "interfaces/request";
import { PushConnect } from "interfaces/response";
import api from "./api";

export const connectService = (payload: ConnectRequest) => {
    return api.push<PushConnect>(PUSH_CONNECT, payload);
};

export const loginService = (payload: LoginRequest, authKey: string) => {
    return api.push<{}>(PUSH_LOGIN, payload, {
        headers: {
            Authorization: authKey,
        },
    });
};

export const filterService = (payload: FilterRequest, authKey: string) => {
    return api.push<{}>(PUSH_FILTERING, payload, {
        headers: {
            Authorization: authKey,
        },
    });
};
