import styled from "@emotion/styled";
import { FontType, Label } from "components/Label";
import React from "react";

interface Props {
    onClick?: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    className?: string;
    classNameLabel?: string;
    label: string;
    fontFamily?: FontType;
    disabled?: boolean;
    children?: React.ReactNode;
}

const ButtonBase = ({ onClick, className, label, fontFamily, classNameLabel, disabled }: Props) => (
    <button disabled={disabled} type="button" className={`btn ${className}`} onClick={onClick}>
        <Label text={label} fontFamily={fontFamily} className={classNameLabel} />
    </button>
);

ButtonBase.defaultProps = {
    className: "",
    classNameLabel: "",
    fontFamily: FontType.NotoSansJPRegular,
};

const Button = styled(ButtonBase)`
    -webkit-tap-highlight-color: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
    &:active {
        opacity: 0.7;
    }
    &:disabled {
        cursor: unset;
        &:active {
            opacity: 1;
        }
    }
    &.btn-dialog {
        width: 92px;
        height: 28px;
        padding: 5px 20px 4px;
        border-radius: 5px;
        background-color: var(--btn-bg-color);
    }
`;

export default Button;
