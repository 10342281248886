import React from "react";
import "./style.scss";
import styled from "@emotion/styled";
import { getReSizeFont } from "utils";

enum FontType {
    NotoSansJPRegular = "NotoSansJPRegular",
    NotoSansJPMedium = "NotoSansJPMedium",
    NotoSansJPBold = "NotoSansJPBold",
    RobotoRegular = "RobotoRegular",
    RobotoMedium = "RobotoMedium",
    RobotoBold = "RobotoBold",
    RobotoCondensedRegular = "RobotoCondensedRegular",
    RobotoCondensedBold = "RobotoCondensedBold",
}

interface Props {
    className?: string;
    text?: string | null;
    fontFamily?: FontType;
    children?: React.ReactNode;
    defaultValue?: string;
    fontSize?: number;
    resizeFont?: {
        maxFontSize: number;
        width: number;
    };
}

const LabelComponent = ({ className, text, children, defaultValue }: Props) => (
    <p className={`label ${className}`}>{text ? text : children ? children : defaultValue}</p>
);

const Label = styled(LabelComponent)`
    font-family: ${(props: Props) => props.fontFamily};
    font-size: ${(props: Props) =>
        props.resizeFont
            ? getReSizeFont(props.resizeFont, props.text)
            : props.fontSize
            ? props.fontSize
            : "-1"}px !important;
`;

Label.defaultProps = {
    className: "",
    defaultValue: "-",
};

export { Label, FontType };
