import { API_KEY, LOGIN_ID, RESPONSE_OK } from "constants/api";
import { CONTENT_VERSION } from "constants/app";
import { Channel, WhiteLabelCode } from "constants/enum";
import { ConnectRequest, FilterRequest, LoginRequest } from "interfaces/request";
import md5 from "md5";
import { connectService, filterService, loginService } from "services/pushService";
import { store } from "store";
import { setPushError } from "store/push/action";
import { logDev } from "utils/logs";

const pushConnect = async () => {
    const paramA: ConnectRequest = {
        channel: Channel.Web,
        companyId: WhiteLabelCode.FXRate,
        appliVer: CONTENT_VERSION,
        apiKey: API_KEY ? API_KEY : "",
    };
    // A
    const connectData = await connectService(paramA);
    if (connectData?.result === RESPONSE_OK) {
        // Push authentication key
        const authKey = md5(`${paramA.companyId}:${paramA.apiKey}:${connectData.authSeed}:${connectData.sessionId}`);
        logDev("Zen MD5 authKey", `${paramA.companyId}:${paramA.apiKey}:${connectData.authSeed}:${connectData.sessionId}`);
        const paramB: LoginRequest = {
            userId: LOGIN_ID,
            sessionId: connectData.sessionId,
        };
        // B
        const loginData = await loginService(paramB, authKey);
        if (loginData?.result === RESPONSE_OK) {
            return {
                sessionId: connectData.sessionId,
                authKey,
            };
        }
    }
};

const pushFilter = async (symbolCodes: string[], sessionId: string, authKey: string) => {
    const paramC: FilterRequest = {
        sessionId,
        symbolCodes,
    };
    // C
    const filterData = await filterService(paramC, authKey);
    if (filterData?.result === RESPONSE_OK) {
        return {
            sessionId,
            authKey,
        };
    }
};

export const pushAuth = async (symbolCodes: string[]) => {
    const auth = await pushConnect();
    if (auth) {
        return pushFilter(symbolCodes, auth.sessionId, auth.authKey);
    } else {
        store.dispatch(setPushError(true));
    }
};

export class PushController {
    private ws: WebSocket | null = null;

    getWebSocket = () => {
        return this.ws;
    };

    initWebSocket = (url: string) => {
        this.ws = new WebSocket(url);
        window.stopPush = false;

        return this.ws;
    };

    closeWebSocket = () => {
        window.stopPush = true;

        return new Promise((resolve) => {
            if (this.ws && this.ws.readyState !== this.ws.CLOSED) {
                this.ws.onclose = async (ev) => {
                    logDev("ws.onclose", ev.reason, ev);
                    resolve(true);
                };
                this.ws.close();
            } else {
                resolve(true);
            }
        });
    };
}
