import { FontType, Label } from "components/Label";
import React from "react";
import { formatNumber, getReSizeFont } from "utils";
import "./style.scss";

interface Props {
    className?: string;
    text?: number | string | null;
    /**
     * unit after text. Ex: %, 円, 口, pt...
     */
    unit?: string;
    classNameUnit?: string;
    fontFamily?: FontType;
    fontFamilyUnit?: FontType;
    /**
     * Rounded to "scale" digits. Default scale = 0
     */
    scale?: number;
    isTrunc?: boolean;
    hasPlus?: boolean;
    resizeFont?: {
        maxFontSize: number;
        width: number;
    };
    resizeUnitFont?: {
        maxFontSize: number;
        width: number;
    };
}

const LabelNumber = (props: Props) => {
    const {
        className,
        text,
        fontFamily,
        unit,
        classNameUnit,
        fontFamilyUnit,
        scale,
        isTrunc,
        hasPlus,
        resizeFont,
        resizeUnitFont,
    } = props;
    const valueFormat = formatNumber(text, scale, isTrunc, hasPlus);

    return (
        <Label
            fontFamily={fontFamily}
            className={`label-number ${className}`}
            fontSize={resizeFont ? getReSizeFont(resizeFont, valueFormat) : undefined}
        >
            {valueFormat}
            {text !== null && text !== "" && typeof text !== "undefined" && !isNaN(+text) && unit ? (
                <Label
                    fontFamily={fontFamilyUnit ?? fontFamily}
                    className={`${classNameUnit ?? className}`}
                    fontSize={resizeUnitFont ? getReSizeFont(resizeUnitFont, valueFormat) : undefined}
                >
                    {unit}
                </Label>
            ) : null}
        </Label>
    );
};

LabelNumber.defaultProps = {
    className: "",
    fontFamily: FontType.RobotoRegular,
    scale: 0,
    isTrunc: false,
    hasPlus: false,
};

export { LabelNumber };
