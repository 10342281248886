import ErrorModal from "components/ErrorModal";
import Loading from "components/Loading";
import RatePage from "pages/RatePage";
import { PushController } from "push/push";
import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { RootState } from "store";

declare global {
    interface Window {
        stopPush: boolean;
    }
}

Modal.setAppElement("#root");

export const push = new PushController();
export const errorRef = React.createRef<ErrorModal>();

const mapStateToProps = ({ api }: RootState) => {
    return {
        loading: api.loading,
    };
};

interface Props extends ReturnType<typeof mapStateToProps> {}

class App extends React.Component<Props> {
    render() {
        const { loading } = this.props;

        return (
            <div className="container">
                <Loading isOpen={loading} />
                <ErrorModal ref={errorRef} />
                <RatePage />
            </div>
        );
    }
}

export default connect(mapStateToProps)(App);
