import ImgWarning from "assets/images/warning.png";
import Button from "components/Button";
import { FontType, Label } from "components/Label";
import text from "constants/ja/common.json";
import React from "react";
import ReactModal from "react-modal";
import styles from "./style.module.scss";

interface State {
    show: boolean;
    message: string;
}

class ErrorModal extends React.Component<{}, State> {
    state: State = {
        show: false,
        message: "",
    };

    windowOffset = 0;
    resetScroll = () => {
        document.body.setAttribute("style", "");
        window.scrollTo(0, this.windowOffset);
    };
    resolve?: (value: unknown) => void = undefined;

    currentTask = new Promise((resolve) => {
        resolve("init");
    });

    show = (message: string) => {
        if (this.state.show) {
            return this.currentTask;
        }

        return (this.currentTask = new Promise((resolve) => {
            this.resolve = resolve;
            this.windowOffset = window.scrollY;
            document.body.setAttribute(
                "style",
                `position: fixed; top: -${this.windowOffset}px; left: 0; right: 0; touch-action: unset; pointer-events: unset;`,
            );
            this.setState({
                show: true,
                message,
            });
        }));
    };

    hide = () => {
        this.setState({
            show: false,
            message: "",
        });
        this.resetScroll();
    };

    componentWillUnmount() {
        this.resetScroll();
    }

    render() {
        const { show, message } = this.state;

        return (
            <ReactModal
                ariaHideApp={false}
                className={styles["modal-style"]}
                overlayClassName={styles["overlay-style"]}
                isOpen={show}
            >
                <div className={`${styles["content-error"]}`}>
                    <img className={styles["img-warning"]} src={ImgWarning} alt="icon warning" />
                    <Label
                        fontFamily={FontType.NotoSansJPRegular}
                        className={`${styles["message"]} fs-13 black03 lh-19`}
                    >
                        {message}
                    </Label>
                    <Button
                        className="btn-dialog"
                        classNameLabel="fs-13 black02 lh-19"
                        label={text.retry}
                        fontFamily={FontType.NotoSansJPRegular}
                        onClick={() => {
                            this.hide();
                            this.resolve?.(true);
                        }}
                    />
                </div>
            </ReactModal>
        );
    }
}

export default ErrorModal;
