import Aud from "assets/images/aud.png";
import Cad from "assets/images/cad.png";
import Chf from "assets/images/chf.png";
import Eur from "assets/images/eur.png";
import Gbp from "assets/images/gbp.png";
import Jpy from "assets/images/jpy.png";
import Nzd from "assets/images/nzd.png";
import Try from "assets/images/try.png";
import Usd from "assets/images/usd.png";
import Zar from "assets/images/zar.png";
import { FontType, Label } from "components/Label";
import React from "react";
import "./style.scss";

interface CurrencyPairType {
    leftFlag: string;
    rightFlag: string;
    name: string;
}

interface Props {
    symbolCode: string;
}

interface SymbolMap {
    [symbol: string]: CurrencyPairType;
}

class CurrencyPair extends React.PureComponent<Props> {
    symbolMap: SymbolMap = {
        "2001": {
            leftFlag: Usd,
            rightFlag: Jpy,
            name: "USD/JPY",
        },
        "2002": {
            leftFlag: Eur,
            rightFlag: Jpy,
            name: "EUR/JPY",
        },
        "2003": {
            leftFlag: Gbp,
            rightFlag: Jpy,
            name: "GBP/JPY",
        },
        "2004": {
            leftFlag: Aud,
            rightFlag: Jpy,
            name: "AUD/JPY",
        },
        "2005": {
            leftFlag: Chf,
            rightFlag: Jpy,
            name: "CHF/JPY",
        },
        "2006": {
            leftFlag: Cad,
            rightFlag: Jpy,
            name: "CAD/JPY",
        },
        "2007": {
            leftFlag: Nzd,
            rightFlag: Jpy,
            name: "NZD/JPY",
        },
        "2008": {
            leftFlag: Zar,
            rightFlag: Jpy,
            name: "ZAR/JPY",
        },
        "2045": {
            leftFlag: Try,
            rightFlag: Jpy,
            name: "TRY/JPY",
        },
        "2010": {
            leftFlag: Eur,
            rightFlag: Usd,
            name: "EUR/USD",
        },
        "2011": {
            leftFlag: Gbp,
            rightFlag: Usd,
            name: "GBP/USD",
        },
        "2012": {
            leftFlag: Aud,
            rightFlag: Usd,
            name: "AUD/USD",
        },
    };

    render() {
        const { symbolCode } = this.props;
        const data = this.symbolMap[symbolCode];

        return (
            <div className="currency-pair">
                <div className="currency-pair-img">
                    <img className="left-flag" src={data.leftFlag} alt="left flag" />
                    <img className="right-flag" src={data.rightFlag} alt="right flag" />
                </div>
                <Label
                    className="currency-pair-name fs-15 lh-23 black03"
                    text={data.name}
                    fontFamily={FontType.RobotoMedium}
                />
            </div>
        );
    }
}

export { CurrencyPair };
