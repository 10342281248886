import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features/url-search-params";
import { Provider } from "react-redux";
import CssVars from "css-vars-ponyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/styles/index.scss";
import reportWebVitals from "./reportWebVitals";
import { store } from "store";

CssVars();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
