import { ENV } from "constants/app";

/* eslint-disable */
let logDev = (message?: any, ...optionalParams: any[]) => {};
let logError = (message?: any, ...optionalParams: any[]) => {};

if (!ENV.PRD) {
    logDev = console.log.bind(console);
    logError = console.error.bind(console);
}

export { logDev, logError };
