export enum MarketSegment {
    OTC = "2",
}

export enum WhiteLabelCode {
    FXRate = "201",
}

export enum Channel {
    Web = "70",
}

export enum Locale {
    Japanese = "1",
}

export enum ErrorCode {
    SystemMaintenance = "WRMA_SORRY_0001",
    SystemFailure = "WRMA_SORRY_0002",
}

export enum RequestTimeout {
    APIRequestTimeoutTime = 10000,
}

export enum ReceivedDataType {
    AuthenticationRequest = "ARQ",
    AuthenticationResult = "ARS",
    Beacon = "BCN",
    Market = "MKT",
    MostRecentContractPrice = "TDP",
    OHLC = "KKA",
    Event = "EVE",
    OrderContract = "ORD",
    Error = "ERR",
}

export enum SendMessageType {
    JSON = "0",
    MessagePack = "4",
}

export enum DisplayMode {
    AllCurrencies = 1,
    MajorCurrencies = 2,
}
