import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import api from "./api/apiReducer";
import push from "./push/pushReducer";

const rootReducer = combineReducers({
    api,
    push,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));

export type RootState = ReturnType<typeof rootReducer>;
