import React from "react";
import { MoonLoader } from "react-spinners";
import styles from "./style.module.scss";

interface Props {
    isOpen: boolean;
}

const Loading = ({ isOpen }: Props) => {
    return isOpen ? (
        <div className={styles["overlay-style"]}>
            <div className={styles["content"]}>
                <MoonLoader size={50} color="#EB5505" loading={isOpen} />
            </div>
        </div>
    ) : null;
};

export default Loading;
