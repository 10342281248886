import { FontType } from "components/Label";
import { LabelNumber } from "components/LabelNumber";
import React from "react";
import "./style.scss";

interface Props {
    className?: string;
    classNameRate: string;
    arrowImg: string;
    isShowArrow: boolean;
    value: number;
    scale: number;
}

class RateNumber extends React.PureComponent<Props> {
    render() {
        const { className, classNameRate, arrowImg, isShowArrow, value, scale } = this.props;

        return (
            <div className={`rate-number ${className}`}>
                <div className="bid-arrow">{isShowArrow && <img src={arrowImg} alt="arrow" />}</div>
                <LabelNumber
                    text={value}
                    className={`fs-32 lh-43 ${classNameRate}`}
                    fontFamily={FontType.RobotoCondensedBold}
                    scale={scale}
                    isTrunc={true}
                    resizeFont={{maxFontSize: 32, width: 108}}
                />
            </div>
        );
    }
}

export { RateNumber };
