import pixelWidth from "string-pixel-width";

export const getReSizeFont = (
    resizeFont: {
        maxFontSize: number;
        width: number;
    },
    text: string | null | undefined,
) => {
    // One character length
    const oneCharLength = (fontSize: number) => {
        return pixelWidth("0", { size: fontSize });
    };

    // Get max character length
    const baseWidth = (fontSize: number) => {
        return Math.ceil(resizeFont.width / oneCharLength(fontSize));
    };

    // Resize font
    let fontSize = resizeFont.maxFontSize;
    while (text && text.length > baseWidth(fontSize)) {
        fontSize = fontSize - 0.5;
    }

    return fontSize < 1 ? 1 : fontSize;
};
