export const AXIOS_TIMEOUT_CODE = "ECONNABORTED";
export const AXIOS_TIMEOUT_ERROR_MESSAGE = "AXIOS_TIMEOUT_ERROR_MESSAGE";
export const API_APP_ERROR_CODE = Object.freeze({
    CANCEL: "CANCEL",
});
export const API_KEY = process.env.REACT_APP_API_KEY;
export const RESPONSE_OK = "OK";

// Push
export const PUSH_CONNECT = "control/connect";
export const PUSH_LOGIN = "control/login";
export const PUSH_FILTERING = "control/filtering";

// API
export const GET_SYMBOL_ALL = "/symbolServiceNs/getSymbolAll";
export const GET_INIT_PRICE_AND_SETTING = "/nowPriceInfoServiceNs/getInitPriceListAndSettings";

// Login
export const LOGIN_ID = "1234567890";
